.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.language--bar {
  position: absolute;
  font-size: 1rem;
  right: 1rem;
}

.language--bar--mobile {
  padding: 1.25rem;
}

.language--link {
  color: white;
  cursor: pointer;
}

.language--seperator {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Scrool Style **/
body {
  background: #fff;
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
  margin: 0 0 1rem;
}

p {
  margin: 0 0 1rem;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1rem;
}

.container--fluid {
  margin: 0 auto;
  padding: 0 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.row--no-gutters {
  margin: 0;
}

.row--no-gutters > .col {
  padding: 0;
}

.col {
  flex: 1;
  padding: 0 1rem;
}

.row > .col {
  margin-bottom: 1rem;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-button {
  background: #333;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: #888;
}

::-webkit-resizer {
  background: #888;
}
/** End Scroll Style **/